<mat-form-field class="example-full-width">
  <mat-label>{{ 'ADMIN.CLIENT_HOURS.EMPLOYEES' | translate }}</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    [value]="query()"
    (input)="onInput($event.target.value)"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event.option.value)">
    <mat-option *ngFor="let employee of filteredEmployee()" [value]="employee.fullName">
      {{ employee.fullName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
